<script setup lang="ts">
import type { SeoBreadcrumb } from '@/types/cms';

defineOptions({
  name: 'MopBreadcrumbs',
});

const { $gtm2 } = useNuxtApp();
const { getCategoryBreadcrumbs } = useMopCategoryTree();
const { breadcrumbsRef } = useMopHeader();
const { getSavedTopCategory } = useMopRouter();
const breadcrumbsWithFallbackRef = computed<SeoBreadcrumb[]>(() => {
  if (breadcrumbsRef.value?.length > 0) {
    return breadcrumbsRef.value;
  }
  return getCategoryBreadcrumbs(getSavedTopCategory());
});

function trackBreadcrumbClick(breadcrumb: SeoBreadcrumb) {
  const breadcrumbLevel = breadcrumbsWithFallbackRef.value.indexOf(breadcrumb) + 1;
  $gtm2.reportLegacyEngagement({
    event: 'navigation_breadcrumbs',
    category: $gtm2.getCurrentPage(),
    label: breadcrumb.name,
    parameter3: $gtm2.getPageCategoryId(),
    parameter5: breadcrumbLevel.toString(),
  });
}
</script>

<template>
  <ul v-if="breadcrumbsWithFallbackRef.length > 1" class="mop-breadcrumbs">
    <li v-for="breadcrumb in breadcrumbsWithFallbackRef" :key="breadcrumb.url" class="mop-breadcrumbs__item">
      <NuxtLink
        :to="$mopI18n.localePath(breadcrumb.url)"
        class="mop-breadcrumbs__item-link"
        no-prefetch
        @click="trackBreadcrumbClick(breadcrumb)"
      >
        {{ breadcrumb.isCategory ? breadcrumb.name.toLowerCase() : breadcrumb.name }}
      </NuxtLink>
    </li>
  </ul>
</template>

<style scoped lang="scss">
.mop-breadcrumbs {
  display: flex;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: capitalize;
  padding: 0 $global-padding;
}

.mop-breadcrumbs__item:not(:last-child)::after {
  content: '';
  padding: 0 3px;
  width: 8px;
  height: 8px;
  border-right: 1px solid;
  border-top: 1px solid;
  margin-left: 2px;
  margin-right: 6px;
  transform: translateY(-1px) rotate(45deg);
  display: inline-block;
}

.mop-breadcrumbs__item-link {
  @include link-not-underlined;
}
</style>
